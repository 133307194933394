/* 
 * 	Core Owl Carousel CSS File
 *	v1.15
 */

/* clearfix */
.owl-carousel:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	overflow: hidden;
	width: 100%;
}
.owl-carousel .owl-wrapper{
	position: relative;
	display: inline-block;
	-webkit-transform: translate3d(0px, 0px, 0px);
	-webkit-perspective: 1000;
}
.owl-carousel .owl-item{
	float: left;
}
.owl-controlls .owl-page,
.owl-controlls .owl-buttons{
	cursor: pointer;
}
.owl-controlls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing { 
    cursor:url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
}





